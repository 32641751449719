<template>
  <div>
    <swiper
      :autoplay="
        !img_flow ? swiper_options.autoplay_f : swiper_options.autoplay
      "
      :loop="swiper_options.loop"
      :speed="swiper_options.speed"
      :slidesPerView="swiper_options.slidesPerView"
      :spaceBetween="swiper_options.spaceBetween"
      :breakpoints="swiper_options.breakpoints"
      :pagination="{ clickable: true }"
      :grabCursor="true"
      :effect="!img_flow ? swiper_options.effect_f : swiper_options.effect"
      :coverflowEffect="swiper_options.coverflowEffect"
    >
      <swiper-slide v-for="l in img_amount" :key="l">
        <img v-lazy="require('@/assets/img/' + img_doc + '/' + l + '.jpg')" />
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
import { reactive } from "vue";
//引入swiper
import SwiperCore, {
  Autoplay,
  Pagination,
  EffectCoverflow,
  Navigation,
  EffectFlip,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.scss";
//设置swiper
SwiperCore.use([Autoplay, Pagination, EffectCoverflow, Navigation, EffectFlip]);
export default {
  name: "banner",
  props: { img_doc: String, img_amount: Number, img_flow: Boolean },
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {};
  },
  setup() {
    //指定swiper的设置
    let swiper_options = reactive({
      autoplay: {
        delay: 2000,
        disableOnInteraction: false,
      },
      autoplay_f: { delay: 2800, disableOnInteraction: false },
      // loop: true,
      speed: 1000,
      slidesPerView: 1,
      spaceBetween: 15,
      effect: "coverflow", //flip
      effect_f: "fade",
      pagination: {
        clickable: true,
      },
      breakpoints: {
        // when window width is >= 200px
        200: {
          slidesPerView: 1,
        },
        // when window width is >= 1200px
        1200: {
          slidesPerView: 1,
        },
      },
      coverflowEffect: {
        rotate: 180,
        stretch: 0,
        depth: 60,
        modifier: 1,
        slideShadows: true,
      },
    });

    return {
      swiper_options,
    };
  },
  methods: {},
  computed: {
    // arr_img() {
    //   if (this.$Utils.isMobile() != "pc端") {
    //     return ["banner_1_mb","banner_2_mb"];
    //   } else return ["banner_1","banner_2"];
    // },
  },
};
</script>
