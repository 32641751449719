<template>
  <div class="">
    <div class="py-5 container">
      <div class="d-flex align-items-center md-flex-wrap lg-flex-wrap">
        <div
          class="d-flex text-orange justify-content-center align-items-center"
        >
          <div class="mb-0 d-flex flex-row">
            <img
              src="@/assets/img/main/media_20240408_224931_4638502498359788652.jpg"
              style="width: 300px"
            />
          </div>
        </div>
      </div>
      <div class="d-flex flex-1023-column">
        <banner
          class="banner1 mt-4"
          :img_doc="'img1'"
          :img_amount="12"
          :img_flow="true"
        />
        <banner
          class="banner1 mt-4"
          :img_doc="'img2'"
          :img_amount="12"
          :img_flow="false"
        />
        <banner
          class="banner1 mt-4"
          :img_doc="'img3'"
          :img_amount="12"
          :img_flow="true"
        />
        <!-- </div>
      <div class="d-flex flex-1023-column"> -->
        <banner
          class="banner1 mt-4"
          :img_doc="'img4'"
          :img_amount="12"
          :img_flow="true"
        />
        <banner
          class="banner1 mt-4"
          :img_doc="'img5'"
          :img_amount="10"
          :img_flow="false"
        />
        <banner
          class="banner1 mt-4"
          :img_doc="'img6'"
          :img_amount="10"
          :img_flow="true"
        />
      </div>
    </div>
    <div class="my-bg-grey">
      <div class="py-5 container">
        <p class="mx-auto intro_p intro_p1 text-center">
          天下父母心，為人父母都是望子成龍、望女成鳳，把所有最好的都給孩子，希望能讓他在未來的人生道路上走得更高更遠，但又希望他可以開心快樂的學習。
          那麼我們就應該把握住學齡前的教育！孩子們黃金發展關鍵期即是２到６歲，只要打下好的基礎與習慣，就是幫助我們的寶貝拿到展望美好未來的敲門磚。
          我們有系統的蒙式教育方式，能在快樂與玩樂中，幫助孩子提升專注力、穩定性、獨立學習能力進而增加邏輯與數學、語文的基礎能力。
          教育這件事是雙向的，我們也歡迎爸爸媽媽一同來參與，共同陪伴孩子們快樂成長，有任何關於孩子的問題，都可以一起討論，互相學習，以期許給孩子最好最快樂的學習。
          <!-- 現在的父母絕大多數都有共同的困擾就是我家的寶貝專注力不夠、穩定度不佳、獨立學習不行等問題，該怎麼辦呢？
          別擔心〜蒙特梭利教學理念針對專注力不夠、穩定度不佳、獨立學習不行的寶貝們有極大的改善，寶貝們只要來上一期之後絕大多數的爸爸媽媽都有明顯的感受到寶貝們專注力、穩定度的改善，也可以獨立學習了！
          相信這都是家長們有目共睹的，改善了以上問題之後進而增加邏輯與數學、語文的基礎能力提升，在快樂與玩樂中學習，對於以後的數學及語文有莫大的穩定基礎。 -->
        </p>
      </div>
    </div>
    <div class="">
      <div class="py-5 container">
        <div class="d-flex align-items-center md-flex-wrap lg-flex-wrap">
          <div
            class="d-flex text-orange justify-content-center align-items-center"
          >
            <div class="mb-0 d-flex flex-row">
              <img
                src="@/assets/img/main/media_20240408_224931_229558323708861200.jpg"
                style="width: 300px"
              />
            </div>
          </div>
        </div>
        <div class="mx-auto mt-3">
          <p class="mx-auto intro_p w-100 mb-3">
            培養小朋友的專注力、耐心度以及獨立性，進一步提升小朋友的邏輯能力與數學能力
          </p>
          <table class="table">
            <tr>
              <th>日常生活教育</th>
              <td>
                從"家"的觀念出發，培養孩子生活自理能力，照顧家庭環境的觀念，並建立良好的品德。
              </td>
            </tr>
            <tr>
              <th>感官教育</th>
              <td>
                藉由感官教育的操作，可精鍊孩子的視覺、聽覺、嗅覺、味覺、觸覺，使孩子的五官更敏銳，進而更聰明有智慧。［訓練專注力，邏輯能力及耐心度］
              </td>
            </tr>
            <tr>
              <th>數學教育</th>
              <td>
                由邏輯思考能力的建立，加上「量」的具體概念，再引入「加、減、乘、除」等具體操作，進入四則運算，建立扎實的教學基礎。
              </td>
            </tr>
            <tr>
              <th>語文教育</th>
              <td>
                六歲之前是幼兒的語言敏感期，因此不但強調母語學習，同時也提供多種語言的學習。
              </td>
            </tr>
            <tr>
              <th>自然科學教育</th>
              <td>
                天文、地質、動物、植物、歷史、地理等文化教育，讓孩子們學習如何照顧動植物、了解自己所居住的大環境、宇宙萬物的奧祕，以及認識各種礦物等。
              </td>
            </tr>
            <tr>
              <th>美語教學</th>
              <td>以蒙特梭利美語教材及環境增加孩子美語聽、說基礎。</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import banner from "./Banner.vue";
export default {
  name: "main1",
  data() {
    return {};
  },
  created() {},
  mounted() {},
  components: { banner },
  computed: {},
  methods: {},
  watch: {},
};
</script>
