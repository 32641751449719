<template>
  <div>
    <div class="position-relative">
      <maintopview />

      <div v-if="Id != 'Reg'">
        <div
          class="bg-linear-green pt-global-header pb-global-header before-bg-linear-white after-bg-linear-white bg-linear-white-upsitedown"
        >
          <div
            class="h-global-header position-absolute top-0 start-0 w-100 bg-linear-white"
          ></div>
          <hometop />
        </div>
        <main1 />
      </div>
      <div v-else class="pt-global-header">
        <reg />
      </div>

      <div class="px-lg-3 my-bg-grey">
        <footerview class="mt-5" />
      </div>
    </div>
  </div>
</template>

<script>
import maintopview from "./main_top.vue";
import footerview from "./footer.vue";
import hometop from "./home_top.vue";
import main1 from "./main1.vue";
import reg from "./Register.vue";
export default {
  name: "Home",
  data() {
    return {};
  },
  created() {},
  mounted() {},
  components: {
    maintopview,
    footerview,
    hometop,
    main1,
    reg,
  },
  computed: {
    Id: function () {
      if (this.$route.params.id != null) return this.$route.params.id;
      else return "";
    },
  },
  methods: {
    click_href(id) {
      if (id == "reg") this.$router.push("/Reg");
      else this.$router.push("/");
    },
  },
  watch: {},
};
</script>
